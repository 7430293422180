/*
Herein is a hodge-podge of modal madness.  Migration is in progress.
The new approach is to consume ScModal from the component library.
*/

import modalQueue from "./modal-queue";
import onsiteMessageModalInit from "./onsite-messaging";
import {
  autoInstantWinWheelInit,
  instantWinWheelInit,
  chooseGameModule,
} from "./instant-win-wheel";
import { trackSpinWheel } from "./iw-ga";
import { createApp, onMounted, ref } from "vue";
import { ScModal, ScCreatePassword } from "@pch/surffers-components";

import { optinInit } from "../optin/optin-controller";
import { useCreatePassword } from "../../apps/use/create-password";

function observeCp() {
  return new Promise((resolve, reject) => {
    if (document.body.dataset.cpDone) resolve();

    const cpObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "data-cp-done"
        ) {
          resolve();
        }
      });
    });
    cpObserver.observe(document.body, { attributes: true });
  });
}

// Hack until badge anims are sc-modals
function observeBadges() {
  return new Promise((resolve, reject) => {
    if (document.body.dataset.badgesDone) resolve();

    const badgeObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "data-badges-done"
        ) {
          resolve();
        }
      });
    });
    badgeObserver.observe(document.body, { attributes: true });
  });
}

function observeIWGame() {
  return new Promise((resolve, reject) => {
    if (document.body.dataset.iwDone) resolve();

    const iwObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "data-iw-done"
        ) {
          resolve();
        }
      });
    });
    iwObserver.observe(document.body, { attributes: true });
  });
}

const onsiteMessageModals = [...document.querySelectorAll(".onsite-messaging")];
onsiteMessageModals.forEach((modal) => {
  if (!modal.hasAttribute("deferred")) {
    onsiteMessageModalInit(modal);
    modalQueue.enqueue(modal);
  }
});

const autoInstantWinWheelModal = document.getElementById(
  "auto-instant-win-wheel"
);
if (autoInstantWinWheelModal) {
  observeCp().then(() => {
    // Hack until badge anims are dialogs

    const gameModule = chooseGameModule();

    if (gameModule?.game_info?.auto_popup == true) {
      autoInstantWinWheelInit(gameModule);
      modalQueue.enqueue(autoInstantWinWheelModal);
      trackSpinWheel();
    } else {
      document.body.dataset.iwDone = true;
    }
  });
} else {
  document.body.dataset.iwDone = true;
}

const instantWinWheelModal = document.getElementById("instant-win-wheel");
if (instantWinWheelModal) {
  instantWinWheelInit(window.PCH?.surveys?.bonusGamesModule?.spin_info);
}

observeIWGame().then(() => {
  PCH.uninav.controller.autoUpdate();
});




const createPassApp = createApp({
  setup() {


    const showCpLb = ref(false);

    function cpClose(reload) {
      if (reload) {
        window.location.reload();
      } else {
        showCpLb.value = false;
        document.body.dataset.cpDone = true;
        resolveFn();
  
      }
    }

    const cp = useCreatePassword(cpClose, window.PCHUSER, PCH?.surveys?.noPassData?.noPassBadgeTokens, PCH?.modals?.createPassText);
    
    onMounted(() => {
      observeBadges().then(() => {
        if (PCHUSER.type === 1 && PCH?.surveys?.noPassData?.noPassBadgeTokens) {
          cp.cpUpdatePayload('noPassBadgeTokensCargo', PCH?.surveys?.noPassData?.noPassBadgeTokensCargo);
          showCpLb.value = true;
        } else {
          document.body.dataset.cpDone = true;
        }
      }).catch((error) => {
        // Handle the error here
        console.warn('An error occurred while observing badges:', error);
        // You can also update the UI or log the error as needed
      });
    });

    

    return {
      showCpLb,
      cpClose,
      ...cp
    }
  },
});

createPassApp.component("sc-modal", ScModal);
createPassApp.component("sc-create-password", ScCreatePassword);
window.cpApp = createPassApp.mount("#createPassApp");

// optin starts here
optinInit();

modalQueue.start();
